@import "variables";

.home-title {
  font-size: 30px;

  @media (min-width: $bp-lg){
    font-size: 58px;
    margin-top: .4em;
  }

  &:before {
    top: .5em;
  }
}

.home-pre-title {
  color: rgba($text-color, .5);
}

#particles {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
